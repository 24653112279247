import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../model/paginated-result';
import { LeadList } from '../../models/LeadList';
import { ListFilter } from '../../models/ListFilter';
import { AuthService } from './auth.service';

const STORAGE_KEY = 'SMSFUNNEL::LEADLISTS';

let interval: any = null;
const importFinished = new EventEmitter<LeadList>();

@Injectable()
export class ListsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  get importFinished() {
    return importFinished;
  }

  startInterval() {
    if (!interval) {
      interval = setInterval(() => {
        let leadLists = null;
        if (this.authService.isLogged()) {
          leadLists = this.getFromStorage();
        }
        if (leadLists) {
          leadLists.forEach((leadList) => {
            if (leadList && leadList.importing) {
              this.verifyStatus(leadList);
            } else {
              this.removeFromStorage(leadList);
            }
          });
        }
      }, 5000);
    }

    return importFinished;
  }

  verifyStatus(leadList: LeadList) {
    this.status(leadList.id).subscribe({
      next: (updatedList) => {
        if (!updatedList.importing) {
          importFinished.emit(updatedList);
          this.removeFromStorage(updatedList);
        }
      },
      error: (err) => {
        if (err.status === 404) {
          this.removeFromStorage(leadList);
        }
      },
    });
  }

  addToStorage(leadList: LeadList) {
    if (!leadList) {
      return;
    }

    this.removeFromStorage(leadList);
    let leadLists = this.getFromStorage();
    if (!leadLists) {
      leadLists = [];
    }

    leadLists.push(leadList);

    this.saveToStorage(leadLists);

    this.startInterval();

    return leadLists;
  }

  removeFromStorage(leadList: LeadList) {
    if (!leadList) {
      return;
    }
    let leadLists = this.getFromStorage();
    if (leadLists) {
      leadLists = leadLists.filter((ll) => ll?.id !== leadList.id);
      if (leadLists.length > 0) {
        this.saveToStorage(leadLists);
      } else {
        localStorage.removeItem(STORAGE_KEY);
      }
    }
    this.startInterval();
  }

  addAllToStorage(leadLists: LeadList[]) {
    this.startInterval();
    let oldLeadLists = this.getFromStorage();
    if (oldLeadLists) {
      leadLists.forEach((leadList) => {
        this.addToStorage(leadList);
      });
    } else {
      this.saveToStorage(leadLists);
    }
  }

  saveToStorage(leadLists: LeadList[]) {
    if (leadLists.length > 0) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(leadLists));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }

  getFromStorage(): LeadList[] {
    const leadLists = localStorage.getItem(STORAGE_KEY);
    if (leadLists) {
      return JSON.parse(leadLists);
    }

    return null;
  }

  listPaginated(
    page: number,
    pageSize: number,
    filter: ListFilter
  ): Observable<PaginatedResult<LeadList[]>> {
    let url = `${environment.apiUrl}/lists?page=${page}&per_page=${pageSize}`;

    if (filter) {
      if (filter.name) {
        url += `&name=${filter.name}`;
      }

      if (filter.integrationId) {
        url += `&integration_id=${filter.integrationId}`;
      }

      if (filter.onlyDynamicLists !== null) {
        url += `&only_dynamic_lists=${filter.onlyDynamicLists}`;
      }
    }

    return this.http.get<PaginatedResult<LeadList[]>>(url).pipe(take(1));
  }

  listAll(): Observable<LeadList[]> {
    return this.http
      .get<LeadList[]>(`${environment.apiUrl}/lists`)
      .pipe(take(1));
  }

  listAllBroadcast(): Observable<LeadList[]> {
    return this.http
      .get<LeadList[]>(`${environment.apiUrl}/lists?only_imported=true`)
      .pipe(take(1));
  }

  findById(id: string): Observable<LeadList> {
    const url = `${environment.apiUrl}/lists/${id}`;
    return this.http.get<LeadList>(url).pipe(take(1));
  }

  status(id: string): Observable<LeadList> {
    const url = `${environment.apiUrl}/lists/${id}/status?no-loading=true`;
    return this.http.get<LeadList>(url).pipe(take(1));
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/lists/${id}`).pipe(take(1));
  }

  save(leadList: LeadList, file: File): Observable<LeadList> {
    if (leadList.id) {
      return this.update(leadList.id, leadList);
    }

    const formData = new FormData();
    formData.append('name', leadList.name);
    formData.append('new_leads', leadList.new_leads);
    formData.append('manual', String(leadList.manual));
    formData.append('dynamic', String(leadList.dynamic));
    formData.append('tags', JSON.stringify(leadList.tags));
    formData.append('integration_id', String(leadList.integration_id));
    formData.append(
      'platform_events',
      JSON.stringify(leadList.platform_events)
    );

    if (file) {
      formData.append('file', file);
    }

    return this.create(formData);
  }

  updateLeadsCount(leadListId: string, leadsCount: number) {
    return this.http
      .put(`${environment.apiUrl}/lists/${leadListId}/leads-count`, {
        leads_count: leadsCount,
      })
      .pipe(take(1));
  }

  private create(formData: FormData): Observable<LeadList> {
    return this.http.post<LeadList>(`${environment.apiUrl}/lists`, formData);
  }

  private update(id: string, leadList: LeadList): Observable<LeadList> {
    return this.http.put<LeadList>(
      `${environment.apiUrl}/lists/${id}`,
      leadList
    );
  }
}
