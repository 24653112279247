<app-page-title icon="fa-user" [title]="title">
  <a
    *ngIf="userId"
    [routerLink]="['/users/new']"
    class="btn btn-info btn-icon-right"
  >
    <i class="fa fa-plus-circle"></i>
    Novo Usuário
  </a>
</app-page-title>

<div class="alert alert-warning" *ngIf="beta" role="alert">
  Este usuário foi migrado para a versão SMSFunnel 2.0 e não pode ser mais
  atualizado.
</div>

<form [formGroup]="userForm" (submit)="save()">
  <div class="card elevated mb-2">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col">
          <div class="form-group">
            <label for="">Nome</label>
            <input
              id="name"
              type="text"
              class="form-control"
              formControlName="name"
              #nameInput
            />
            <small
              *ngIf="userForm.get('name').errors?.required"
              class="text-danger"
            >
              Preencha o nome do usuário
            </small>
            <small
              *ngIf="userForm.get('name').errors?.minlength"
              class="text-danger"
            >
              O nome do usuário deve ter pelo menos 5 caracteres
            </small>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <div class="form-group">
            <label for="">CPF</label>
            <input
              id="document"
              type="text"
              class="form-control"
              formControlName="document"
              (input)="formatCpf($event)"
              #documentInput
            />
            <small
              *ngIf="userForm.get('document').errors?.required"
              class="text-danger"
            >
              Preencha o CPF
            </small>
            <small
              *ngIf="
                !userForm.get('document').errors?.required &&
                userForm.get('document').errors?.cpfInvalid
              "
              class="text-danger"
            >
              O CPF informado é inválido.
            </small>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              id="email"
              type="email"
              class="form-control"
              formControlName="email"
            />
            <small
              *ngIf="userForm.get('email').errors?.required"
              class="text-danger"
            >
              Preencha o e-mail
            </small>
            <small
              *ngIf="userForm.get('email').errors?.email"
              class="text-danger"
            >
              E-mail inválido
            </small>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="pix_key">Chave Pix:</label>
            <input
              id="pix_key"
              type="text"
              class="form-control"
              formControlName="pix_key"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="phone">Telefone</label>
            <input
              appPhone
              id="phone"
              type="text"
              class="form-control"
              formControlName="phone"
            />
            <small
              *ngIf="userForm.get('phone').errors?.required"
              class="text-danger"
            >
              Preencha o telefone
            </small>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="profile">Perfil</label>
            <div *ngIf="profiles$ | async as profiles; else loading">
              <select
                class="form-control"
                id="profile"
                formControlName="profile_id"
              >
                <option *ngFor="let profile of profiles" [value]="profile.id">
                  {{ profile.name }}
                </option>
              </select>
            </div>

            <small
              *ngIf="userForm.get('profile_id').errors?.required"
              class="text-danger"
            >
              Preencha o Perfil
            </small>
          </div>
        </div>

        <div class="col" *ngIf="showCommission">
          <div class="form-group">
            <label for="profile">Comissão (%)</label>
            <input
              type="number"
              class="form-control"
              name="commission"
              id="commission"
              formControlName="commission"
            />
          </div>
        </div>
      </div>

      <div class="col">
        <div class="form-group">
          <label for="operation_type">Modelo de negócio</label>
          <div>
            <select
              class="form-control"
              id="operation_type"
              formControlName="operation_type"
            >
              <option
                *ngFor="let operationType of operationTypes"
                [value]="operationType.value"
              >
                {{ operationType.label }}
              </option>
            </select>
          </div>

          <small
            *ngIf="userForm.get('operation_type').errors?.required"
            class="text-danger"
          >
            Preencha com o modelo de negócio
          </small>
        </div>
      </div>

      <div class="row my-4">
        <div class="col">
          <app-switcher
            id="user_active"
            label="Usuário ativo?"
            display="block"
            [(checked)]="active"
          />
        </div>
        <div class="col">
          <app-switcher
            id="analytics_sync"
            label="Exibir relatórios?"
            display="block"
            [(checked)]="analyticsSync"
          />
        </div>
        <div class="col">
          <app-switcher
            id="content_tag_enabled"
            label="Informar conteúdo de mensagem por webhook?"
            display="block"
            [(checked)]="contentTagEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="sms_token_validated"
            label="Remover token?"
            display="block"
            [(checked)]="tokenVerifyEnabled"
          />
        </div>
      </div>

      <div class="row my-4">
        <div class="col">
          <app-switcher
            id="generate_password"
            label="Gerar Senha?"
            display="block"
            [(checked)]="generatePassword"
            (change)="toggleGeneratePassword()"
          />
        </div>
        <div class="col">
          <app-switcher
            id="credit_payment_enabled"
            label="Pagamento com Crédito?"
            display="block"
            [(checked)]="creditPaymentEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="massive_sms_enabled"
            label="SMS Massivo?"
            display="block"
            [(checked)]="massiveSmsEnabled"
          />
        </div>
        <div class="col" *ngIf="showCommission">
          <app-switcher
            id="api_enabled"
            label="Habilitar API?"
            display="block"
            [(checked)]="partnersAPIEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="beta"
            label="Usuário Beta?"
            display="block"
            [(checked)]="beta"
            (change)="openModal()"
            (ngModelChange)="modalClose()"
          />
        </div>
      </div>

      <div class="row my-4">
        <div class="col">
          <app-switcher
            id="fake_request"
            label="Fake Request?"
            display="block"
            [(checked)]="fakeRequest"
          />
        </div>
        <div class="col">
          <app-switcher
            id="leads_export"
            label="Exportar Leads"
            display="block"
            [(ngModel)]="exportLeads"
            [(checked)]="exportLeads"
            (ngModelChange)="onSwitchChange($event)"
          ></app-switcher>
        </div>
      </div>

      <div class="row my-4">
        <div class="col">
          <app-switcher
            id="broadcast_send_interval"
            label="Forçar intevalo para broadcast"
            display="block"
            [(checked)]="broadcastSendInterval"
          />
        </div>
        <div class="col">
          <app-switcher
            id="revision_sms_enabled"
            label="Revisar SMS?"
            display="block"
            [(checked)]="revisionSmsEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="revision_automations_enabled"
            label="Revisar Automações?"
            display="block"
            [(checked)]="revisionAutomationsEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="massive_sms_enabled"
            label="SMS Massivo?"
            display="block"
            [(checked)]="massiveSmsEnabled"
          />
        </div>
        <div class="col" *ngIf="!showCommission"></div>
        <div class="col" *ngIf="showCommission">
          <app-switcher
            id="api_enabled"
            label="Habilitar API?"
            display="block"
            [(checked)]="partnersAPIEnabled"
          />
        </div>
      </div>

      <div class="row my-4">
        <div class="col">
          <app-switcher
            id="credit_payment_enabled"
            label="Pagamento com Crédito?"
            display="block"
            [(checked)]="creditPaymentEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="is_billable"
            label="Emitir notas?"
            display="block"
            [(ngModel)]="billingEnabled"
            [(checked)]="billingEnabled"
          />
        </div>
        <div class="col">
          <app-switcher
            id="beta"
            label="Usuário Beta?"
            display="block"
            [(checked)]="beta"
            (change)="openModal()"
            (ngModelChange)="modalClose()"
          />
        </div>
        <div class="col">
          <app-switcher
            id="is_billable"
            label="Emitir notas?"
            display="block"
            [(ngModel)]="billingEnabled"
            [(checked)]="billingEnabled"
          />
        </div>
        <div class="col">&nbsp;</div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="password">Nova senha</label>
          <input
            type="password"
            id="password"
            class="form-control"
            formControlName="password"
            [readonly]="generatePassword"
          />
          <span *ngIf="userForm.get('password').touched">
            <small
              class="text text-danger"
              *ngIf="userForm.get('password').errors?.required"
            >
              Nova senha é obrigatória
            </small>
            <small
              class="text text-danger"
              *ngIf="userForm.get('password').errors?.minlength"
            >
              A nova senha deve ter no mínimo 8 caracteres
            </small>
          </span>
        </div>

        <div class="form-group col-md-6">
          <label for="password_confirmation">Confirmação da nova senha</label>
          <input
            type="password"
            id="password_confirmation"
            class="form-control"
            formControlName="password_confirmation"
            [readonly]="generatePassword"
          />
          <span *ngIf="userForm.get('password_confirmation').touched">
            <small
              class="text text-danger"
              *ngIf="userForm.get('password_confirmation').errors?.required"
            >
              Confirmação da nova senha é obrigatória
            </small>
            <small
              class="text text-danger"
              *ngIf="userForm.get('password_confirmation').errors?.minlength"
            >
              A nova senha deve ter no mínimo 8 caracteres
            </small>
            <small
              class="text text-danger"
              *ngIf="userForm.get('password_confirmation').errors?.different"
            >
              Nova senha não foi confirmada corretamente
            </small>
          </span>
        </div>
      </div>

      <fieldset class="my-3 card">
        <legend>Configurações de saída:</legend>
        <div class="row my-2">
          <div class="col">
            <label for="automation_provider_account_id">Conta Automação</label>
            <div *ngIf="providerAccounts.length > 0; else loading">
              <select
                class="form-control"
                id="automation_provider_account_id"
                formControlName="automation_provider_account_id"
              >
                <option
                  *ngFor="let providerAccount of providerAccounts"
                  [value]="providerAccount.id"
                >
                  {{ providerAccount.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <label for="broadcast_provider_account_id">Conta Broadcast</label>
            <div *ngIf="providerAccounts.length > 0; else loading">
              <select
                class="form-control"
                id="broadcast_provider_account_id"
                formControlName="broadcast_provider_account_id"
              >
                <option
                  *ngFor="let providerAccount of providerAccounts"
                  [value]="providerAccount.id"
                >
                  {{ providerAccount.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <label for="shortener_id">Encurtador:</label>
            <div *ngIf="shorteners$ | async as shorteners; else loading">
              <select
                class="form-control"
                id="shortener_id"
                formControlName="shortener_id"
              >
                <option
                  *ngFor="let shortener of shorteners"
                  [value]="shortener.id"
                >
                  {{ shortener.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col">
            <app-switcher
              id=""
              label="Pix prioritário (OTP)?"
              display="block"
              [(checked)]="pixPriority"
            />
          </div>
        </div>
      </fieldset>

      <div class="row">
        <div class="col mt-2">
          <button class="btn btn-primary me-1" [disabled]="userForm.invalid">
            Salvar
          </button>

          <a [routerLink]="['/dashboard']" class="btn btn-secondary">
            {{ userId ? "Voltar" : "Cancelar" }}
          </a>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #loading>
  <span class="form-control"> Carregando... </span>
</ng-template>

<app-clients *ngIf="showCommission" [user]="user"></app-clients>

<ng-template #modalBetaConfirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
  </div>
  <div class="modal-body">
    <p>
      Você está prestes a iniciar a migração de um usuário para uma versão mais
      recente do sistema.
    </p>
    <p>
      Este processo pode causar indisponibilidade temporária na conta durante
      algumas horas. Certifique-se de que está ciente das consequências antes de
      continuar.
    </p>
    <p>
      Você está migrando a conta
      <span class="text-primary">{{ user.email }}</span> para o SMSFunnel 2.0.
      Por favor, confirme o endereço de e-mail do usuário para prosseguir com a
      migração:
      <input
        style="margin-top: 15px"
        type="email"
        class="form-control"
        [(ngModel)]="emailConfirmation"
      />
    </p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalClose()">
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="emailConfirmation !== user.email"
      (click)="modalConfirm(emailConfirmation)"
    >
      Confirmar
    </button>
  </div>
</ng-template>
