import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BroadcastsService } from 'src/app/shared/services/broadcasts.service';
import {
  ADMINISTRATOR,
  AGENCY_CLIENT,
  CLIENT,
  PARTNER_CLIENT,
} from 'src/app/shared/services/user.service';
import { urlValidatorService } from 'src/app/shared/validators/url.validator.service';

import {
  IMPORTING,
  SENT,
  SENT_WITH_ERRORS,
} from 'src/app/models/BroadcastStatus';
import { BlacklistValidatorService } from '../../../shared/validators/blacklist.validator.service';
import { Broadcast } from 'src/app/models/Broadcast';
import { LeadList } from 'src/app/models/LeadList';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { ListsService } from 'src/app/shared/services/lists.service';
import * as BroadcastType from 'src/app/models/BroadcastType';
import { UserCreditInfo } from 'src/app/models/UserCreditInfo';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { ThemePalette } from '@angular/material/core';

interface ProfileOption {
  id: string;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss'],
})
export class BroadcastComponent implements OnInit, OnDestroy {
  title = 'Criar envio em massa';

  isSaving: boolean;
  startPosition = 0;
  endPosition = 0;

  color: ThemePalette = 'primary';

  userCreditInfo: UserCreditInfo;

  tags = [
    { tag: 'meu_link', text: 'Meu link (encurtado)', broadcastType: ['all'] },
    { tag: 'campaign', text: 'Nome da automação', broadcastType: ['all'] },
    { tag: 'list', text: 'Nome da Lista', broadcastType: ['list'] },
    {
      tag: 'first_name',
      text: 'Primeiro nome do Lead',
      broadcastType: ['list', 'client'],
    },
    { tag: 'email', text: 'E-mail do Lead', broadcastType: ['client'] },
  ];

  changeDate: boolean;
  broadcast: Broadcast;
  broadcastId: string;
  broadcastForm: UntypedFormGroup;
  broadcastType: string;
  lists: LeadList[] = [];
  sendNow = false;
  removeContacts = false;
  subscriptions: Subscription[] = [];
  user: User;

  profiles: ProfileOption[] = [
    { id: CLIENT, name: 'Cliente', selected: true },
    { id: AGENCY_CLIENT, name: 'Agência', selected: true },
    { id: PARTNER_CLIENT, name: 'Parceiro', selected: true },
  ];

  sendLeadNumber = false;

  isDataMenorQueAtual(dataSelecionada: Date): boolean {
    const dataAtual = new Date();
    return dataSelecionada < dataAtual;
  }

  get tagsForType(): any[] {
    return this.tags.filter(
      (t) =>
        t.broadcastType.includes(this.broadcastType) ||
        t.broadcastType.includes('all')
    );
  }

  get numbersCount(): number {
    const numbers = this.broadcastForm.get('numbers').value;
    let count = numbers.split('\n').filter((n) => n.trim() !== '').length;

    if (!numbers.includes('\n')) {
      count = numbers.split(',').filter((n) => n.trim() !== '').length;
    }

    return count;
  }

  get userCredits(): number {
    if (this.userCreditInfo) {
      return this.userCreditInfo.credits - this.userCreditInfo.total_reserved;
    }
    return 0;
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private broadcastsService: BroadcastsService,
    private listsService: ListsService,
    private blacklistValidator: BlacklistValidatorService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUser();

    this.broadcastForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      message: ['', Validators.required],
      numbers: [''],
      profile_id: [''],
      lead_list_id: [''],
      scheduled_date: [''],
      broadcast_type_id: [''],
      url: ['', urlValidatorService()],
    });

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (params.id) {
          this.broadcastId = params.id;
          this.findById();
        }
      })
    );

    this.subscriptions.push(
      this.dashboardService.userCreditsInfo().subscribe((userCreditInfo) => {
        this.userCreditInfo = userCreditInfo;
        if (userCreditInfo.credits <= 0) {
          this.toastService.error('Usuário não possui créditos.');
          this.router.navigate(['/broadcasts']);
        }
      })
    );
  }

  isImporting(broadcast: Broadcast): boolean {
    return broadcast.broadcast_status_id === IMPORTING;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  findById() {
    this.subscriptions.push(
      this.broadcastsService
        .findById(this.broadcastId)
        .subscribe((broadcast) => {
          if (
            broadcast.broadcast_status_id === SENT ||
            broadcast.broadcast_status_id === SENT_WITH_ERRORS
          ) {
            this.router.navigate(['/broadcasts/view', broadcast.id]);
          }

          const broadcastType = BroadcastType.getBroadcastTypeName(
            broadcast.broadcast_type_id
          );
          this.changeBroadcastType(broadcastType);
          this.broadcast = broadcast;
          this.sendLeadNumber = broadcast.send_lead_number;
          this.broadcastForm.patchValue(broadcast);
        })
    );
  }

  loadLists(): void {
    if (this.broadcastType === 'list' && this.lists.length === 0) {
      this.subscriptions.push(
        this.listsService
          .listAllBroadcast()
          .subscribe((lists) => (this.lists = lists))
      );
    }
  }

  changeBroadcastType(broadcastType: string): void {
    this.title = 'Enviar broadcast para ';
    this.broadcastType = broadcastType;

    if (broadcastType === 'list') {
      this.title += 'lista';
    } else if (broadcastType === 'number') {
      this.title += 'números';
    } else if (broadcastType === 'client') {
      this.title += 'clientes da SMSFunnel';
    }

    this.broadcastForm.patchValue({
      numbers: '',
      lead_list_id: '',
      broadcast_type_id: BroadcastType.getBroadcastTypeId(broadcastType),
    });

    this.loadLists();
  }

  addTag(tag: string): void {
    let message = this.broadcastForm.get('message').value || '';

    const size = message.length + ` {${tag}} `.length;
    if (size < 150) {
      const beforeText = message.substring(0, this.startPosition);
      const afterText = message.substring(this.endPosition, message.length);

      message = beforeText + ` {${tag}} ` + afterText;

      this.broadcastForm.patchValue({ message });
    } else {
      this.toastService.error('Não há espaço suficiente para inserir esta TAG');
    }
  }

  toggleChangeDate(): void {
    this.changeDate = true;
    this.broadcastForm.patchValue({ scheduled_date: null });
  }

  isValid(): boolean {
    const broadcast = this.broadcastForm.getRawValue() as Broadcast;

    if (!this.sendNow) {
      const actualDate = new Date();
      const scheduledDate = new Date(broadcast.scheduled_date);

      if (scheduledDate < actualDate) {
        this.toastService.error(
          'A data do agendamento deve ser uma data futura!'
        );

        return false;
      }
    }

    if (this.broadcastType === 'list') {
      if (!broadcast.lead_list_id) {
        this.toastService.error('Selecione uma lista!');
        return false;
      }

      const leadList = this.lists.find((l) => l.id == broadcast.lead_list_id);

      if (!leadList) {
        this.toastService.error('Selecione uma lista!');
        return false;
      }

      if (this.userCredits < leadList.leads_count) {
        this.showUserWithoutCreditsMessage(
          leadList.leads_count - this.userCredits
        );
        return false;
      }
    }

    if (
      this.broadcastType === 'number' &&
      this.numbersCount > this.userCredits
    ) {
      this.showUserWithoutCreditsMessage(this.numbersCount - this.userCredits);
      return false;
    }

    if (broadcast.url && !broadcast.message.includes('{meu_link}')) {
      this.toastService.error(
        'Você preencheu o campo "Meu Link", mas não incluiu a variável {meu_link} na mensagem.'
      );
      return false;
    }

    if (broadcast.message.includes('{meu_link}')) {
      if (!broadcast.url) {
        this.toastService.error(
          'Você está utilizando a TAG {meu_link} mas não preencheu o campo "Meu Link"!'
        );
        return false;
      }

      const size = broadcast.message.length + 15;
      if (size > 150) {
        this.toastService.error(
          `Ao incluir um link, a mensagem ficará com ${size} caracteres.`
        );
        return false;
      }
    }

    const error = this.blacklistValidator.validate(broadcast.message);
    if (error) {
      this.toastService.error(error.message);
      return false;
    }

    if (this.broadcastType === 'client') {
      const selectedProfiles = this.profiles.filter((p) => p.selected);
      if (selectedProfiles.length === 0) {
        this.toastService.error(
          'Você precisa selecionar ao menos um dos perfis de clientes'
        );
        return false;
      }
    }

    if (this.sendLeadNumber && !broadcast.url) {
      this.toastService.error(
        'Para enviar o número dos Leads você precisa preencher o campo Meu Link'
      );
      return false;
    }

    return true;
  }

  save(): void {
    const broadcast = this.broadcastForm.getRawValue() as Broadcast;
    if (this.sendNow) {
      broadcast.scheduled_date = '';
    }

    if (broadcast.scheduled_date) {
      broadcast.scheduled_date = new Date(
        new Date(broadcast.scheduled_date).toLocaleString('en-US', {
          timeZone: 'America/Sao_Paulo',
        })
      );
    }

    broadcast.remove_contacts = this.removeContacts;
    broadcast.send_lead_number = this.sendLeadNumber;

    if (!this.isValid()) {
      return;
    }

    if (this.broadcastType === 'client') {
      broadcast.profiles = this.profiles
        .filter((p) => p.selected)
        .map((p) => p.id);
    }

    if (broadcast.id) {
      if (broadcast.lead_list_id) {
        delete broadcast.remove_contacts;
      }

      this.subscriptions.push(
        this.broadcastsService.update(broadcast).subscribe(
          () => {
            this.toastService.success('Broadcast alterado com sucesso!');
            this.router.navigate(['/broadcasts']);
          },
          (res) =>
            this.toastService.error(
              res?.error?.message || 'Erro ao alterar broadcast!'
            )
        )
      );
    } else {
      const message = broadcast.scheduled_date
        ? 'Broadcast agendado com sucesso'
        : 'Broadcast cadastrado com sucesso!';

      this.subscriptions.push(
        this.broadcastsService.send(broadcast).subscribe(
          (result) => {
            this.toastService.success(message);
            this.router.navigate(['/broadcasts']);
          },
          (res) => {
            this.toastService.error(
              res?.error?.message || 'Erro ao enviar broadcast!'
            );
          }
        )
      );
    }
  }

  setPosition(e: any) {
    this.startPosition = e.target.selectionStart;
    this.endPosition = e.target.selectionEnd;
  }

  private showUserWithoutCreditsMessage(recharge: number) {
    const message = `Créditos insuficientes para enviar este broadcast. Recarregue ${recharge} créditos para enviar.`;
    this.toastService.error(message);
  }
}
